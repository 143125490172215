<!-- 销量 -->
<template>
  <div class="visualization-sale-volume-container">
    <div class="sale-volume-tag">
      <div class="sale-volume-tag-title">
        <img
          src="../../../assets/image/tag.png"
          alt=""
          class="sale-volume-tag-img"
        />
        <span class="sale-volume-name">销量</span>
      </div>
      <div class="sale-volume-label">
        <span class="sale-volume-label-name">销量额:</span>
        <span class="sale-volume-label-num">{{ totalDeal }}</span>
      </div>
    </div>
    <!-- 图表 -->
    <div
      class="saleVolume-echarts"
      id="sale-echarts"
      style="height: 100%; width: 100%"
    ></div>
  </div>
</template>

<script>
import { getSaleVolumeData } from "@/utils/apis";
export default {
  data() {
    return {
      audienceChart: null,
      timer: null,
      sales: [], //存放销量
      salesTime: [], //存放时间
      totalDeal: 0,
    };
  },
    props: ["train", "studentInfo"],
  mounted() {
    this.audienceChart = this.$echarts.init(
      document.getElementById("sale-echarts")
    );
    let resizeTimer = null;
    // 因为主页面存在多个图表，仅最后一个组件执行了自适应，所以要使用window.addEventListener的方式去监听自适应
    // 子组件中的window.onresize只获取到了最后一个echarts
    // 因为箭头函数会改变this指向，指向windows。所以先把this保存
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.audienceChart.resize();
      }, 100);
    });
    self.showSaleVolume();
    this.getTimerSalevolumeData(3000);
  },
  methods: {
    getTimerSalevolumeData(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
           user_id: this.train ? this.train.user_id : this.studentInfo?this.studentInfo.user_id:'',
          train_id: this.train ? this.train.train_id : this.studentInfo?this.studentInfo.train_id:'',
          room_id: this.train ? this.train.room_id : this.studentInfo?this.studentInfo.room_id:'',
        };
        getSaleVolumeData(params).then((res) => {
          if (res.code === 200) {
            this.totalDeal = res.data.total_deal_number;
            res.data.list.forEach((item) => {
              this.sales.push(item.deal_number);
              this.salesTime.push(item.create_time);
              if (this.sales.length > 10 && this.salesTime.length > 10) {
                this.sales.shift();
                this.salesTime.shift();
              }
            });
            this.showSaleVolume();
          }
        });
      }, seconds);
    },
    // 销量图表
    showSaleVolume() {
      this.audienceChart.setOption({
        backgroundColor: "rgba(0,0,0,0)",
        tooltip: {
          show: true,
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.5)",
          axisPointer: {
            lineStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: "#A7D6FF",
                  },
                  {
                    offset: 0.5,
                    color: "#fff",
                  },
                  {
                    offset: 1,
                    color: "#A7D6FF",
                  },
                ],
                global: false,
              },
            },
          },
        },
        legend: {
          right: 10,
          top: 0,
          itemGap: 5, // 各个item之间的间隔，单位px，默认为10，
          itemWidth: 20, // 图例图形宽度
          itemHeight: 5, // 图例图形高度
          textStyle: {
            color: "red",
            fontWeight: "normal",
            fontSize: 14,
          },
        },
        grid: {
          top: "20%",
          left: "15%",
          right: "5%",
          bottom: "12%",
        },
        xAxis: [
          {
            type: "category",
            data: this.salesTime,
            axisTick: {
              show: false, // 是否显示坐标轴轴线
            },
            axisLabel: {
              color: "#fff",
              interval: "auto",
            },
            splitLine: {
              show: false,
            },
            boundaryGap: true,
            axisLine: {
              //坐标轴轴线相关设置。
              show: true,
              inside: false,
              lineStyle: {
                color: "#3F52A2",
              },
            },
          },
        ],

        yAxis: [
          {
            type: "value",
            min: 0,
            splitNumber: 4,
            splitLine: { show: true },
            axisLabel: {
              //坐标轴刻度标签的相关设置。
              show: true,
              textStyle: {
                color: "#fff",
              },
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "#3F52A2",
              },
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                color: "rgba(40, 33, 252, 0.1)",
                type: "solid",
              },
            },
            show: true,
          },
        ],

        series: [
          {
            type: "bar",
            barMaxWidth: 20,
            zlevel: 10,
            barGap: "100%",
            data: this.sales?this.sales:'暂无数据',
            itemStyle: {
              normal: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#4B45FF",
                    },
                  ],
                },
                barBorderRadius: [30, 30, 0, 0],
              },
            },
          },
          {
            type: "bar",
            itemStyle: {
              normal: {
                color: "rgba(0,0,0,0)",
              },
            },
            silent: true,
            barWidth: 20,
            barGap: "-125%",
            data: "",
          },
        ],
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.visualization-sale-volume-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  .sale-volume-tag {
    display: flex;
    margin: 20px 0 27px 21px;
    .sale-volume-tag-title {
      flex: 1;
      .sale-volume-tag-img {
        margin-right: 12px;
      }
      .sale-volume-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .sale-volume-label {
      margin-right: 19px;
      .sale-volume-label-name {
        font-size: 16px;
        color: #ffffff;
      }
      .sale-volume-label-num {
        font-size: 16px;
        color: #4b45ff;
      }
    }
  }

  .saleVolume-echarts {
    left: -20px;
    top: -60px;
  }
}
</style>
