<template>
  <div class="visualization-online-number-container">
    <div class="online-number-tag">
      <div class="online-number-tag-title">
        <img
          src="../../../assets/image/tag.png"
          alt=""
          class="online-number-tag-img"
        />
        <span class="online-number-name">在线人数</span>
      </div>
      <div class="online-number-label">
        <span class="online-number-label-name">累计:</span>
        <span class="online-number-label-num">{{ onlineNumberTotal }}</span>
      </div>
    </div>
    <!-- 图表 -->
    <div
      class="online-number-echarts"
      id="number-echarts"
      style="width: 100%; height: 100%"
    ></div>
  </div>
</template>

<script>
import { getOnlineNumber } from "@/utils/apis";
export default {
  data() {
    return {
      onlineNumberecharts: null,
      timer: null,
      onlineNumber: [],
      onlineTime: [],
      onlineNumberTotal: "",
    };
  },
    props: ["train", "studentInfo"],
  mounted() {
    this.onlineNumberecharts = this.$echarts.init(
      document.getElementById("number-echarts")
    );
    let resizeTimer = null;
    // 因为主页面存在多个图表，仅最后一个组件执行了自适应，所以要使用window.addEventListener的方式去监听自适应
    // 子组件中的window.onresize只获取到了最后一个echarts
    // 因为箭头函数会改变this指向，指向windows。所以先把this保存
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.onlineNumberecharts.resize();
      }, 10);
    });
    self.showOnlineNumberEcharts();
    this.getOnlineNumberData(3000);
  },
  methods: {
    getOnlineNumberData(seconds) {
      this.timer = window.setInterval(() => {
        let params = {
           user_id: this.train ? this.train.user_id : this.studentInfo?this.studentInfo.user_id:'',
          train_id: this.train ? this.train.train_id : this.studentInfo?this.studentInfo.train_id:'',
          room_id: this.train ? this.train.room_id : this.studentInfo?this.studentInfo.room_id:'',
        };
        getOnlineNumber(params)
          .then((res) => {
            let time = [];
            let online = [];
            if (res.code === 200) {
              this.onlineNumberTotal = res.data.online_total_number_total;
              res.data.list.forEach((item) => {
                time.push(item.create_time);
                online.push(item.online_total_number);
              });
              this.onlineNumber = online;
              this.onlineTime = time;
              this.showOnlineNumberEcharts();
            }
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
          });
      }, seconds);
    },
    showOnlineNumberEcharts() {
      this.onlineNumberecharts.setOption({
        tooltip: {
          trigger: "axis",
          axisPointer: {
            lineStyle: {
              color: "rgba(0,0,0,0)",
            },
          },
        },
        legend: {
          icon: "rect",
          itemWidth: 14,
          itemHeight: 5,
          itemGap: 13,
          data: ["在线人数", "在线人数"],
          right: "35%",
          textStyle: {
            fontSize: 12,
            color: "#fff",
          },
          bottom: "1%",
        },
        grid: {
          left: "1.5%",
          right: "5%",
          bottom: "7%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 14,
                color: "#fff",
              },
            },
            splitLine: {
              show: true,
              lineStyle: {
                opacity: 0.7,
                color: "rgba(75, 69, 255, 0.09)",
              },
            },
            data: this.onlineTime,
          },
        ],
        // dataZoom: [
        //   {
        //     show: true,
        //     realtime: true,
        //     start: 0,
        //     end: 100,
        //     bottom: "2.5%",
        //   },
        //   {
        //     type: "inside",
        //     realtime: true,
        //     start: 65,
        //     end: 85,
        //     bottom: "10%",
        //   },
        // ],
        dataZoom: [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            bottom: "2.5%",
            start: 0,
            end: 100,
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ],
        yAxis: [
          {
            type: "value",

            name: "单位（w）",
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: "rgba(0,0,0,0)",
              },
            },
            axisLabel: {
              margin: 15,
              textStyle: {
                fontSize: 14,
                color: "#fff",
              },
            },
            splitLine: {
              lineStyle: {
                opacity: 0.9,
                color: "rgba(75, 69, 255, 0.09)",
              },
            },
          },
        ],
        series: [
          {
            name: "在线人数",
            type: "line",
            smooth: true,
            symbol: "none",
            symbolSize: 5,
            showSymbol: false,
            lineStyle: {
              normal: {
                width: 3,
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(75, 69, 255, 1)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(75, 69, 255, 0.1)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(75, 69, 255, 0.1)",
                shadowBlur: 10,
              },
            },
            itemStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: "rgba(75, 69, 255, 1)",
                  },
                  {
                    offset: 1,
                    color: "rgba(75, 69, 255, 0.1)",
                  },
                ]),
              },
              emphasis: {
                color: "rgba(75, 69, 255, 1)",
                borderColor: "rgba(75, 69, 255, 0.2)",
                extraCssText: "box-shadow: 8px 8px 8px rgba(0, 0, 0, 1);",
                borderWidth: 10,
              },
            },
            data: this.onlineNumber,
          },
        ],
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
};
</script>

<style lang="scss" scoped>
.visualization-online-number-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 0.2rem;
  .online-number-tag {
    display: flex;
    margin: 0.2rem 0 0.27rem 0.21rem;
    .online-number-tag-title {
      flex: 1;
      .online-number-tag-img {
        margin-right: 0.12rem;
      }
      .online-number-name {
        font-size: 0.16rem;
        color: #fff;
      }
    }
    .online-number-label {
      margin-right: 0.19rem;
      .online-number-label-name {
        font-size: 0.16rem;
        color: #ffffff;
      }
      .online-number-label-num {
        font-size: 0.16rem;
        color: #4b45ff;
      }
    }
  }
  .online-number-echarts {
    bottom: 20%;
  }
}
</style>
