<!-- 观众来源 -->
<template>
  <div class="visualization-audience-source-container">
    <div class="audience-source-tag">
      <div class="source-tag-title">
        <img src="../../../assets/image/tag.png" class="source-tag-img" />
        <span class="source-tag-name">观众来源</span>
      </div>
      <!-- <div class="source-tag-label" @click="goto">
        <img
          src="../../../assets/image/paimingbiaoqian.png"
          class="source-tag-label-img"
        />
        <span class="source-tag-label-name">返回</span>
      </div> -->
    </div>

    <!-- 男女比例 -->
    <div class="audience-source-sex">
      <div class="man-box">
        <el-progress
          type="circle"
          :percentage="sex_mam"
          :strokeWidth="14"
          color="rgb(75,69,255)"
          :width="100"
          style="margin-left: 79px"
          class="man"
        >
        </el-progress>
        <span class="iconfont icon-nan" style="font-size: 28px; color: #727599"
          >&#xe710;</span
        >
      </div>
      <div class="female-box">
        <el-progress
          type="circle"
          :percentage="sex_woman"
          :strokeWidth="14"
          color="rgb(255,62,108)"
          :width="100"
          style="margin-left: 74px"
          class="female"
        ></el-progress>
        <span class="iconfont icon-nv" style="font-size: 28px; color: #727599"
          >&#xe644;</span
        >
      </div>
      <!-- 下划线 -->
      <div class="line"></div>
    </div>

    <!-- 图表 -->
    <div class="audience-sourece-echarts">
      <div id="audience-echarts" class="sourece-echarts"></div>
    </div>
  </div>
</template>

<script>
import { getAudienceData } from "@/utils/apis";
export default {
  name: "audienceSource",
  props: ["train", "studentInfo"],
  data() {
    return {
      timer: "",
      audienceSourceEcharts: null,
      chartsTitleStyle: {
        color: "#fff",
        fontSize: 14,
        fontWeight: 400,
      },
      age_data: [],
      sex_mam: 0,
      sex_woman: 0,
    };
  },
  mounted() {
    this.audienceSourceEcharts = this.$echarts.init(
      document.getElementById("audience-echarts")
    );
    let resizeTimer = null;
    // 因为主页面存在多个图表，仅最后一个组件执行了自适应，所以要使用window.addEventListener的方式去监听自适应
    // 子组件中的window.onresize只获取到了最后一个echarts
    // 因为箭头函数会改变this指向，指向windows。所以先把this保存
    const self = this;
    window.addEventListener("resize", function () {
      clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        self.audienceSourceEcharts.resize();
      }, 100);
    });
    this.showAudienceEcharts();
    this.getTimerAudienceData(3000);
  },
  methods: {
    // 切换大屏
    // goto() {
    //   this.$router.push({
    //     path: "/live/screen",
    //   });
    // },
    // 定时获取观众来源
    getTimerAudienceData(senconds) {
      this.timer = setInterval(() => {
        let params = {
          user_id: this.train
            ? this.train.user_id
            : this.studentInfo
            ? this.studentInfo.user_id
            : "",
          train_id: this.train
            ? this.train.train_id
            : this.studentInfo
            ? this.studentInfo.train_id
            : "",
        };
        getAudienceData(params)
          .then((res) => {
            if (res.code === 200) {
              this.sex_mam = res.data.sex_data.man;
              this.sex_woman = res.data.sex_data.woman;
              let arr = [];
              res.data.age_data.forEach((item) => {
                let obj = {
                  name: `${item.name}岁`,
                  value: item.proportion,
                };
                arr.push(obj);
              });
              this.age_data = arr;
              arr = [];
              this.audienceSourceEcharts = this.$echarts.init(
                document.getElementById("audience-echarts")
              );
              this.showAudienceEcharts();
            }
          })
          .catch((err) => {
            this.$message({
              type: "error",
              message: err,
            });
          });
      }, senconds);
    },
    // 年龄分布图表
    showAudienceEcharts() {
      let data = this.age_data;
      this.audienceSourceEcharts.setOption({
        title: {
          textStyle: this.chartsTitleStyle,
          top: 17,
          left: 23,
        },
        series: [
          {
            type: "pie",
            radius: ["70%", "100%"],
            center: ["45%", "40%"],
            avoidLabelOverlap: false,
            color: [
              "#4B45FF",
              "#12C4C4",
              "#51DC8E",
              "#FFA200",
              "#FF3E6C",
              "#1B1558",
            ],
            top: "22%",
            bottom: "11%",
            left: "16%",
            right: "16%",
            itemStyle: {
              borderRadius: 4,
              borderWidth: 4,
              borderColor: "#0C0E3F",
            },
            roseType: "radius",
            label: {
              show: true,
              position: "outside",
              fontSize: 16,
              fontWeight: 400,
              color: "#a4a4af",
              formatter: "{a|{c}%}\n{e|{b}}\n{hr|}",
              rich: {
                hr: {
                  backgroundColor: "auto",
                  borderRadius: 6,
                  width: 0,
                  height: 6,
                  padding: [6, 0, 0, -12],
                },
                a: {
                  padding: [-20, 10, -20, 15],
                },
                e: {
                  padding: [12, 10, -20, 15],
                },
              },
            },
            labelLine: {
              show: true,
              length: 15,
              length2: 26,
              lineStyle: {
                color: "#DDDDDD ",
              },
            },
            data: data,
          },
        ],
      });
      getDefaultSelected(this.audienceSourceEcharts);
      function getDefaultSelected(myChart) {
        let index = 0;
        myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: 0,
        });
        myChart.on("mouseover", (e) => {
          if (e.dataIndex !== index) {
            myChart.dispatchAction({
              type: "downplay",
              seriesIndex: 0,
              dataIndex: index,
            });
          }
        });
        myChart.on("mouseout", (e) => {
          index = e.dataIndex;
          myChart.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: e.dataIndex,
          });
        });
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss" scoped>
.visualization-audience-source-container {
  height: 100%;
  width: 100%;
  background: rgba(12, 14, 63, 1);
  border: 0.01rem solid rgba(12, 14, 63, 1);
  box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  .audience-source-tag {
    display: flex;
    margin: 20px 0 22px 21px;
    .source-tag-title {
      .source-tag-img {
        margin-right: 12px;
      }
      .source-tag-name {
        font-size: 16px;
        color: #fff;
      }
    }
    .source-tag-label {
      cursor: pointer;
      height: 100%;
      .source-tag-label-img {
        position: absolute;
        top: -22px;
        left: 23%;
      }
      .source-tag-label-name {
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        position: absolute;
        top: 2%;
        left: 24.7%;
      }
    }
  }
  .audience-source-sex {
    position: relative;
    height: 100px;
    ::v-deep .el-progress--circle .el-progress__text,
    .el-progress--dashboard .el-progress__text {
      color: white;
    }
    .man-box {
      position: absolute;
      left: 4%;
      .iconfont {
        position: absolute;
        right: 20%;
        top: 35%;
      }
      ::v-deep .el-progress--circle .el-progress__text,
      .el-progress--dashboard .el-progress__text {
        position: absolute;
        left: 60%;
        top: 90%;
        color: white;
      }
    }
    .el-progress ::v-deep path:first-child {
      stroke: rgba(255, 255, 255, 0.1);
    }
    }

    .female-box {
      position: absolute;
      left: 45%;
      top: 0%;
      .iconfont {
        position: absolute;
        left: 63%;
        top: 35%;
      }
      ::v-deep .el-progress--circle .el-progress__text,
      .el-progress--dashboard .el-progress__text {
        position: absolute;
        left: 60%;
        top: 10%;
        color: white;
      }
    }
    // .line {
    //   border-bottom: 1px dashed #3f52a2;
    //   width: 500px;
    //   position: absolute;
    //   left: 41.5%;
    //   margin-left: -200px;
    //   top: 110px;
    // }
  }

  .audience-sourece-echarts {
    height: 1%;
    flex: 1;
    padding: 20px 10px 0px 0px;
    .sourece-echarts {
      width: 100%;
      height: 100%;
      margin-left: 20px;
    }
  }
</style>
